

















































































































import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { formatRelative } from "@/shared/helpers/dateHelpers";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { formatCurrency, formatPercentage } from "@/shared/helpers/formattingHelpers";
import BaseModal from "@/components/shared/BaseModal.vue";
import RateRegisterLineModal from "@/components/administration/rate-register/RateRegisterLineModal.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { AdminRouteNames } from "@/shared/enums/RouteNames/adminRouteNamesEnum";
import { useRoute, useRouter } from "@/shared/useHelpers";
import { api } from "@/api/api";
import { ApiGetClientAccountRateDto } from "@/api/generated/Api";
import { authService } from "@/auth/authService";
import { AuthRole } from "@/auth/auth.constants";

export default defineComponent({
  name: "CommunicationTemplateListPage",
  components: {
    BaseTableFiltered,
    BaseLayout,
    BaseModal,
    RateRegisterLineModal,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const search = ref<string>("");
    const modalData = ref(getInitialModalData());
    const rateRegister = ref<ApiGetClientAccountRateDto[]>([]);

    const goToRateRegisterList = () => {
      router.push({
        name: AdminRouteNames.RateRegisterList,
      });
    };

    const loadRateRegister = async () => {
      rateRegister.value = (
        await api.clientaccountrate.getClientAccountRatesAsync(+route.params.rateRegisterYear)
      ).data.map((rateRegisterLine) => ({
        ...rateRegisterLine,
        rest:
          rateRegisterLine.grantedAmount && rateRegisterLine.balance
            ? rateRegisterLine.grantedAmount - rateRegisterLine.balance
            : 0,
      }));
    };

    const closeModal = async () => {
      modalData.value.showModal = false;
      modalData.value = getInitialModalData();

      await loadRateRegister();
    };

    const openEditRateRegisterLineModal = useOpenModal(ModalType.Edit, "satser", modalData);

    onMounted(async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        await loadRateRegister();
      });
    });

    return {
      headers,
      search,
      rateRegister,
      formatRelative,
      statusOptions,
      formatCurrency,
      formatPercentage,
      openEditRateRegisterLineModal,
      modalData,
      closeModal,
      AdminRouteNames,
      goToRateRegisterList,
      canEditRateRegisterLine: computed(() => authService.hasRoles(AuthRole.ModifyClientAccountRate)),
    };
  },
});

const headers = [
  { text: "Arrangør", value: "memberOrganizationName" },
  { text: "Klientnummer", value: "clientAccountNumber" },
  { text: "AdmDelt_S", value: "centralCourseRates.participantPrice" },
  { text: "AdmTime_S", value: "centralCourseRates.hourlyPrice" },
  { text: "Adm%_S", value: "centralCourseRates.percentageOfTotalCostsPerCourse" },
  { text: "Tak_S", value: "centralCourseRates.maximumAmount" },
  { text: "AdmDelt_L", value: "localCourseRates.participantPrice" },
  { text: "AdmTime_L", value: "localCourseRates.hourlyPrice" },
  { text: "Adm%_L", value: "localCourseRates.percentageOfTotalCostsPerCourse" },
  { text: "Tak_L", value: "localCourseRates.maximumAmount" },
  { text: "Fellestiltak", value: "productContributions.commonActions" },
  { text: "Forbundskurs", value: "productContributions.organizationCourses" },
  { text: "Statsstøtte", value: "governmentContribution" },
  { text: "Tildeling", value: "grantedAmount" },
  { text: "Saldo", value: "balance" },
  { text: "Rest sum", value: "rest" },
  { text: "Status", value: "isActive" },
  { text: "Handlinger", value: "actions", sortable: false },
];

const statusOptions = [
  { text: "Aktiv", value: true },
  { text: "Inaktiv", value: false },
];
