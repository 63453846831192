var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseLayout',{staticClass:"px-0",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-icon',{staticClass:"py-1"},[_vm._v("mdi-format-list-bulleted")]),_vm._v(" Satsregister ")]},proxy:true}])},[(_vm.modalData.showModal)?_c('BaseModal',{attrs:{"maxWidth":400}},[_c('CreateRateRegisterModal',{attrs:{"headline":_vm.modalData.modalHeadline,"modalType":_vm.modalData.modalType},on:{"close":function($event){return _vm.closeModal()}}})],1):_vm._e(),[_c('BaseTableFiltered',{attrs:{"headers":_vm.headers,"items":_vm.rateRegisters,"search":_vm.search,"sort-by":"lastUpdated","sort-desc":true},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk","single-line":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.canCreateRateRegister)?_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Lag nytt satsregister","icon":"mdi-plus","data-cy":"openCreateNewRateRegister"},on:{"handleClick":function($event){return _vm.openCreateNewRateRegister()}}}):_vm._e()]},proxy:true},{key:"item.lastUpdated",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatLocalizedDateAndTime(value))+" ")]}},{key:"item.isActive",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Aktiv" : "Inaktiv")+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"diplayRateRegister"},on:{"click":function($event){return _vm.openDisplayRateRegisterPage(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Rediger satsregister for "+_vm._s(item.year))])])]}}],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }