var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseLayout',{staticClass:"px-0",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.goToRateRegisterList}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}])},[_c('span',[_vm._v("Tilbake til liste over satsregistre")])]),_vm._v(" Satsregister ")]},proxy:true}])},[(_vm.modalData.showModal)?_c('BaseModal',{attrs:{"axWidth":1200}},[_c('RateRegisterLineModal',{attrs:{"headline":_vm.modalData.modalHeadline,"modalType":_vm.modalData.modalType,"existingItemModal":_vm.modalData.existingItem,"existingItemId":_vm.modalData.existingItemId},on:{"close":_vm.closeModal}})],1):_vm._e(),[_c('BaseTableFiltered',{attrs:{"headers":_vm.headers,"items":_vm.rateRegister,"search":_vm.search},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"2"}}),_c('th',{staticClass:"table-group-header",attrs:{"colspan":"4"}},[_vm._v("Sentrale forbundskurs")]),_c('th',{staticClass:"table-group-header",attrs:{"colspan":"4"}},[_vm._v("Lokale forbundskurs")]),_c('th',{staticClass:"table-group-header",attrs:{"colspan":"2"}},[_vm._v("Produktbidrag")]),_c('th',{attrs:{"colspan":"5"}})])])]},proxy:true},{key:"title",fn:function(){return [_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk","single-line":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.memberOrganizationName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.memberOrganizationId === 0 ? "Intern regnskapsavdeling i AOF" : item.memberOrganizationName)+" ")]}},{key:"item.centralCourseRates.participantPrice",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatCurrency(value) : "-")+" ")]}},{key:"item.centralCourseRates.hourlyPrice",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatCurrency(value) : "-")+" ")]}},{key:"item.centralCourseRates.adminPercentage",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatPercentage(value) : "-")+" ")]}},{key:"item.centralCourseRates.maximumAmount",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatCurrency(value) : "-")+" ")]}},{key:"item.localCourseRates.participantPrice",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatCurrency(value) : "-")+" ")]}},{key:"item.localCourseRates.hourlyPrice",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatCurrency(value) : "-")+" ")]}},{key:"item.localCourseRates.adminPercentage",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatPercentage(value) : "-")+" ")]}},{key:"item.localCourseRates.maximumAmount",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatCurrency(value) : "-")+" ")]}},{key:"item.productContributions.commonActions",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatCurrency(value) : "-")+" ")]}},{key:"item.productContributions.organizationCourses",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatCurrency(value) : "-")+" ")]}},{key:"item.governmentContribution",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatCurrency(value) : "-")+" ")]}},{key:"item.grantedAmount",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatCurrency(value) : "-")+" ")]}},{key:"item.balance",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatCurrency(value) : "-")+" ")]}},{key:"item.rest",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatCurrency(value) : "-")+" ")]}},{key:"item.isActive",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Aktiv" : "Inaktiv")+" ")]}},(_vm.canEditRateRegisterLine)?{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"data-cy":"openEditRateRegisterLineModal"},on:{"click":function($event){return _vm.openEditRateRegisterLineModal(item, item.memberOrganizationName)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Rediger satser for "+_vm._s(item.memberOrganizationId === 0 ? "Intern regnskapsavdeling i AOF" : item.memberOrganizationName))])])]}}:null],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }