





































































































































































import { api } from "@/api/api";
import { ApiAccountRatesDto, ApiGetClientAccountRateDto, ApiProductContributionDto } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateMinimumValue, validateMaximumValue } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, PropType, Ref, ref } from "@vue/composition-api";
import Vue from "vue";

interface ClientAccountRate extends ApiGetClientAccountRateDto {
  centralCourseRates: ApiAccountRatesDto;
  localCourseRates: ApiAccountRatesDto;
  productContributions: ApiProductContributionDto;
}

export default defineComponent({
  name: "RateRegisterLineModal",
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    existingItemModal: {
      type: Object as PropType<ApiGetClientAccountRateDto | null>,
      default: null,
    },
    existingItemId: {
      type: Number,
    },
  },
  emits: ["close", "update"],
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const rates: Ref<ClientAccountRate> = ref(
      props.existingItemModal ? createRatesData(deepCloneObject(props.existingItemModal)) : getRatesInitialData()
    );

    const onSubmit = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const isValid = (refs.form as Vue & { validate: () => boolean }).validate();
        if (!isValid) {
          return;
        }

        if (props.existingItemModal) {
          await api.clientaccountrate.updateClientAccountRatesAsync(
            rates.value.year,
            rates.value.memberOrganizationId,
            rates.value
          );
          openNotification(store, NotificationItemType.Success, "Satser er oppdatert.");
        }

        emit("close");
        emit("update");
      });
    };

    return {
      rates,
      onSubmit,
      validateMinimumValue,
      validateMaximumValue,
      validateEmptyOrNonNegative,
    };
  },
});

const createRatesData = (initialData: ApiGetClientAccountRateDto): ClientAccountRate => ({
  ...initialData,
  centralCourseRates: initialData.centralCourseRates || {
    participantPrice: 0,
    hourlyPrice: 0,
    percentageOfTotalCostsPerCourse: 0,
    maximumAmount: 0,
  },
  localCourseRates: initialData.localCourseRates || {
    participantPrice: 0,
    hourlyPrice: 0,
    percentageOfTotalCostsPerCourse: 0,
    maximumAmount: 0,
  },
  productContributions: initialData.productContributions || {
    commonActions: 0,
    organizationCourses: 0,
  },
});

const getRatesInitialData = (): ClientAccountRate => ({
  year: 0,
  memberOrganizationName: "",
  memberOrganizationId: 0,
  clientAccountNumber: 0,
  centralCourseRates: {
    participantPrice: 0,
    hourlyPrice: 0,
    percentageOfTotalCostsPerCourse: 0,
    maximumAmount: 0,
  },
  localCourseRates: {
    participantPrice: 0,
    hourlyPrice: 0,
    percentageOfTotalCostsPerCourse: 0,
    maximumAmount: 0,
  },
  productContributions: {
    commonActions: 0,
    organizationCourses: 0,
  },
  governmentContribution: 0,
  grantedAmount: 0,
  balance: 0,
  isActive: true,
  inserted: "",
  insertedBy: 0,
  insertedByName: "",
  updated: "",
  updatedBy: 0,
  updatedByName: "",
});

// Validate number to either be empty or non negative
const validateEmptyOrNonNegative = [(v: any) => !v || +v >= 0 || "Tallet må være 0 eller høyere."];
