


















































import { api } from "@/api/api";
import CreateRateRegisterModal from "@/components/administration/rates-register/CreateRateRegisterModal.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { AdminRouteNames } from "@/shared/enums/RouteNames/adminRouteNamesEnum";
import { formatLocalizedDateAndTime } from "@/shared/helpers/dateHelpers";
import { useRouter } from "@/shared/useHelpers";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { ApiGetClientAccountRateOverviewDto } from "@/api/generated/Api";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { authService } from "@/auth/authService";
import { AuthRole } from "@/auth/auth.constants";

export default defineComponent({
  name: "RateRegisterListPage",
  components: {
    BaseTooltipIconButton,
    BaseTableFiltered,
    BaseLayout,
    BaseModal,
    CreateRateRegisterModal,
  },
  setup() {
    const search = ref<string>("");
    const router = useRouter();
    const rateRegisters = ref<ApiGetClientAccountRateOverviewDto[]>([]);
    const items = mockData;
    const modalData = ref(getInitialModalData());

    const openDisplayRateRegisterPage = (item: ApiGetClientAccountRateOverviewDto) => {
      router.push({
        name: AdminRouteNames.RateRegister,
        params: {
          rateRegisterYear: item.year?.toString(),
        },
      });
    };

    const openCreateNewRateRegister = useOpenModal(ModalType.Add, "satsregister", modalData);

    const closeModal = () => {
      modalData.value.showModal = false;
      resetModalData();
      loadRateRegisters();
    };

    const loadRateRegisters = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        rateRegisters.value = (await api.clientaccountrate.getClientAccountRateOverviewAsync()).data;
      });
    };

    const resetModalData = () => {
      modalData.value = getInitialModalData();
    };

    onMounted(async () => {
      await loadRateRegisters();
    });

    return {
      rateRegisters,
      items,
      search,
      headers,
      modalData,
      openCreateNewRateRegister,
      formatLocalizedDateAndTime,
      closeModal,
      openDisplayRateRegisterPage,
      canCreateRateRegister: computed(() => authService.hasRoles(AuthRole.CreateClientAccountRates)),
    };
  },
});

const mockData = [
  {
    startDate: new Date(2022, 1, 1),
    endDate: new Date(2022, 12, 31),
    year: 2022,
    lastUpdated: new Date(2022, 8, 1, 22, 13, 13, 105),
    isActive: true,
  },
];

const headers = [
  { text: "År", value: "year" },
  { text: "Oppdatert", value: "lastUpdated" },
  { text: "Status", value: "isActive" },
  { text: "Handlinger", value: "actions", sortable: false },
];
